<template>
  <div class="corpus-wrap">
    <van-nav-bar
      class="van-nav-bar--fixed"
      title="生肖资料图"
      left-arrow
      @click-left="onClickLeft"
    ></van-nav-bar>
    <div class="clearfix" style="margin-top: 10px">
      <img
        class="images"
        src="http://cs.369cy.com/uploads/sximg/shengxiao1.jpg"
        alt=""
      />
      <img
        class="images"
        src="http://cs.369cy.com/uploads/sximg/shengxiao2.jpg"
        alt=""
      />
      <img
        class="images"
        src="http://cs.369cy.com/uploads/sximg/shengxiao3.jpg"
        alt=""
      />
      <img
        class="images"
        src="http://cs.369cy.com/uploads/sximg/shengxiao4.jpg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    onClickLeft() {
      // 返回上一级页面
      this.$router.back();
    },
  },
};
</script>
<style>
.images {
  width: 100%;
}
</style>
